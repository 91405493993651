import * as image from "../data/constants/images";
import * as e from "../data/enums";
import React, { useEffect } from "react";
// import { LOGO } from "../data/constants/images";

export default function Home(): JSX.Element {
	// const [animateHero, setAnimateHero] = useState(false);
	// const [animateButtonWatchDemo, setAnimateButtonWatchDemo] = useState(false);
	// const [animateButtonTryForFree, setAnimateButtonTryForFree] = useState(false);
	// const [animateFeatures, setAnimateFeatures] = useState(false);
	// const [animationClass, setAnimationClass] = useState("");

	useEffect(() => {
		const animationsPlayedSchools = localStorage.getItem("animationsPlayedHome");
		if (animationsPlayedSchools) return;
		// setAnimationClass("hide");
		// setAnimateHero(true);
		// setAnimateButtonWatchDemo(true);
		// setAnimateButtonTryForFree(true);
		// setAnimateFeatures(true);

		localStorage.setItem("animationsPlayedHome", "true");
	}, []);

	// const animationClassHero = animateHero ? "animate-fade-in" : animationClass;
	// const animationClassWatchDemo = animateButtonWatchDemo ? "animate-fade-in" : animationClass;
	// const animationClassTryForFree = animateButtonTryForFree ? "animate-fade-in" : animationClass;
	// const animationClassFeatures = animateFeatures ? "animate-fade-in" : animationClass;

	return (
        <main className="home" style={{ marginTop: '-40px', marginBottom: '-50px' }}>
            <div
                className="hero-text"
                style={{ 
                    textAlign: "center", 
                    padding: "20px 20px 20px 20px",
                    backgroundColor: "#ffffff", 
                    borderRadius: "8px",
                    marginBottom: '10px' // Reduced bottom margin
                }}
            >
                <h1 style={{ color: "#000000", fontSize: "2.5em" }}>N O T A D U O</h1>
                <p style={{ color: "#000000", fontSize: "1.25em" }}>Share notes without any hassle.</p>
            </div>

            <section className="features" style={{ marginTop: '-50px' }}>
                <div className="feature">
                    <img
                        className="screenshot"
                        src={image.IMAGES[e.Images.HOME]}
                        alt="screenshot of student portal home page"
                    />
                </div>
            </section>


			<div
				className="button-container"
				style={{
					display: "flex",
					justifyContent: "center", // This centers the buttons horizontally
					alignItems: "center", // This centers the buttons vertically (if needed)
					flexDirection: "column", // This stacks the buttons vertically; use 'row' for horizontal stacking
					gap: "10px", // Adjusts space between buttons
				}}
			>
				{/* Uncomment the following line if you want to include the Watch Demo button */}
				{/* <button className="button-secondairy" style={{ width: 'desired-width' }}> Watch Demo </button> */}
				<button
					className="signUp"
					onClick={() => (window.location.href = "https://web.notaduo.com")}
					style={{ width: "200px" }} // Adjust the width as needed
				>
					Web Version
				</button>
				<button
					className="signUp"
					onClick={() => (window.location.href = "https://apps.apple.com/us/app/notaduo/id1639505053?l=en")}
					style={{ width: "200px" }} // Adjust the width as needed
				>
					App Store
				</button>
				<button
					className="signUp"
					onClick={() =>
						(window.location.href = "https://play.google.com/store/apps/details?id=com.notaduo&gl=US")
					}
					style={{ width: "200px" }} // Adjust the width as needed
				>
					Play Store
				</button>
			</div>
		</main>
	);
}
